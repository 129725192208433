<section class="container main-section">
  <h2 class="d-flex main-section-title justify-content-right">{{ ('main.' + type + '.title' | translate) }}</h2>
  @if ((mediaQueryService.up(1200) | async)) {
    <div class="d-flex gap-48 main-section__box">
      <div class="left-section w-50">
        @if (type === 'scan' || type === 'model') {
          <div class="d-flex align-items-center justify-content-between gap-24 buttons-extraContainer">
            <div class="buttons-extra d-flex flex-column w-50">
              <button (click)="setType('scan')" class="button" [class.active]="(mainService.activeType$ | async) === SectionTypeEnum.SCAN">
                <span translate>main.buttons.scan</span>
              </button>
              <button (click)="setType('model')" class="button" [class.active]="(mainService.activeType$ | async) === SectionTypeEnum.MODEL">
                <span translate>main.buttons.model</span>
              </button>
            </div>
            <p class="w-50 text-right" [innerHTML]="('main.' + type + '.extra' | translate)"></p>
          </div>
          <p class="mt-75 main-content" [innerHTML]="('main.' + type + '.content' | translate)"></p>
        } @else {
          <p class="content-paragraph main-content" [innerHTML]="('main.' + type + '.content' | translate)"></p>
          <div class="buttons d-flex flex-column gap-12">
            <div>
              <button (click)="setType('scan')" class="button" [class.active]="(mainService.activeType$ | async) === SectionTypeEnum.SCAN">
                <span translate>main.buttons.scan</span>
              </button>
            </div>
            <button (click)="setType('model')" class="button" [class.active]="(mainService.activeType$ | async) === SectionTypeEnum.MODEL">
              <span translate>main.buttons.model</span>
            </button>
          </div>
        }

        @if (config[type].section1) {
          <p class="mt-75 carousel-title" [innerHTML]="('main.' + type + '.section1.title') | translate"></p>
          <ngx-slick-carousel #slickCarouselSection1 [config]="slickConfig">
            <div *ngFor="let i of [].constructor(config[type].section1); let idx = index">
              <div ngxSlickItem>
                <p class="font-italic" [innerHTML]="'main.' + type + '.section1.' + (idx + 1) | translate"></p>
              </div>
            </div>
          </ngx-slick-carousel>
        }

        <p class="mt-50 font-bold" [innerHTML]="('main.' + type + '.icons.title') | translate"></p>
        <div class="icon-section" [ngClass]="{'five-icons': config[type].icons === 5, 'four-icons': config[type].icons === 4}">
          <div class="icon-item" *ngFor="let i of [].constructor(config[type].icons); let idx = index">
            <img [src]="'assets/media/' + type + '/icon/' + (idx + 1) + '.svg'" alt="Icon">
            <p>{{ 'main.' + type + '.icons.' + (idx + 1) | translate }}</p>
          </div>
        </div>

        @if (config[type].section2) {
          <p class="mt-50 font-bold carousel-title" [innerHTML]="('main.' + type + '.section2.title') | translate"></p>
          <ngx-slick-carousel #slickCarouselSection2 [config]="slickConfig">
            <div *ngFor="let i of [].constructor(config[type].section2); let idx = index">
              <div ngxSlickItem>
                <p [innerHTML]="'main.' + type + '.section2.' + (idx + 1) | translate"></p>
              </div>
            </div>
          </ngx-slick-carousel>
        }

      </div>
      <div class="right-section w-50 d-flex flex-column">
        <app-hero-component [type]="type"/>
        <div class="d-flex w-50 align-items-center justify-content-between w-100 contact-banner">
          <div class="contact-banner-text">
            <p translate>common.questions.text1</p>
            <p translate>common.questions.text2</p>
          </div>
          <div class="contact-banner-buttons gap-30 d-flex align-items-center justify-content-left">
            <a href="tel:{{ 'footer.phone2whats' | translate }}">{{ 'common.questions.callButton' | translate }}</a>
            <a href="mailto:{{ 'footer.email1' | translate }}">{{ 'common.questions.writeButton' | translate }}</a>
          </div>
        </div>
        <div class="type-selector">
          <button class="type-button"
                  *ngFor="let section of ['inventory', 'digitization', 'bim', 'integration', 'it']"
                  [class.active]="type === section"
                  data-title="{{('main.' + type + '.title' | translate)}}"
                  (click)="setType(section)">
            <img [src]="'assets/images/plus.svg'" alt="{{section}}">
          </button>
        </div>

        <app-partners [type]="type"/>

        @if (type === 'digitization') {
          <div class="digitization_section">
            <p translate>home.partners.digitization.title</p>
            <span translate>home.partners.digitization.content</span>
          </div>
        }
      </div>
    </div>
  } @else {
    <div class="d-flex gap-48 main-section__box">
      <div class="left-section w-50 position-relative">
        @if (type === 'scan' || type === 'model') {
          <div class="main-section__box--content d-flex align-items-center justify-content-between gap-24 ">
            <p class="w-50 text-right" [innerHTML]="('main.' + type + '.extra' | translate)"></p>
          </div>
          <p class="main-content" [innerHTML]="('main.' + type + '.content' | translate)"></p>
        } @else {
          <p class="content-paragraph main-content" [innerHTML]="('main.' + type + '.content' | translate)"></p>
        }
        <app-hero-component [type]="type"/>
        <div class="type-selector">
          <button class="type-button"
                  *ngFor="let section of ['inventory', 'digitization', 'bim', 'integration', 'it']"
                  [class.active]="type === section"
                  title="{{section}}"
                  (click)="setType(section)">
            <img [src]="'assets/images/plus.svg'" alt="{{section}}">
          </button>
        </div>

      </div>
      <div class="right-section w-50 d-flex flex-column">
        @if (config[type].section1) {
          <p class="mt-75 carousel-title" [innerHTML]="('main.' + type + '.section1.title') | translate"></p>
          <ngx-slick-carousel #slickCarouselSection1 [config]="slickConfig">
            <div *ngFor="let i of [].constructor(config[type].section1); let idx = index">
              <div ngxSlickItem>
                <p class="font-italic" [innerHTML]="'main.' + type + '.section1.' + (idx + 1) | translate"></p>
              </div>
            </div>
          </ngx-slick-carousel>
        }

        <p class="mt-50 font-bold" [innerHTML]="('main.' + type + '.icons.title') | translate"></p>
        <div class="icon-section" [ngClass]="{'five-icons': config[type].icons === 5, 'four-icons': config[type].icons === 4}">
          <div class="icon-item" *ngFor="let i of [].constructor(config[type].icons); let idx = index">
            <img [src]="'assets/media/' + type + '/icon/' + (idx + 1) + '.svg'" alt="Icon">
            <p>{{ 'main.' + type + '.icons.' + (idx + 1) | translate }}</p>
          </div>
        </div>

        @if (config[type].section2) {
          <p class="mt-50 font-bold carousel-title" [innerHTML]="('main.' + type + '.section2.title') | translate"></p>
          <ngx-slick-carousel #slickCarouselSection2 [config]="slickConfig">
            <div *ngFor="let i of [].constructor(config[type].section2); let idx = index">
              <div ngxSlickItem>
                <p [innerHTML]="'main.' + type + '.section2.' + (idx + 1) | translate"></p>
              </div>
            </div>
          </ngx-slick-carousel>
        }

        <app-partners [type]="type"/>

        @if (type === 'digitization') {
          <div class="digitization_section">
            <p translate>home.partners.digitization.title</p>
            <span translate>home.partners.digitization.content</span>
          </div>
        }
        <div class="d-flex w-50 align-items-center justify-content-between w-100 contact-banner">
          <div class="contact-banner-text">
            <p translate>common.questions.text1</p>
            <p translate>common.questions.text2</p>
          </div>
          <div class="contact-banner-buttons gap-30 d-flex align-items-center justify-content-left">
            <a href="tel:{{ 'footer.phone2whats' | translate }}">{{ 'common.questions.callButton' | translate }}</a>
            <a href="mailto:{{ 'footer.email1' | translate }}">{{ 'common.questions.writeButton' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  }
</section>