import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

enum DIR {
  UP = 'u',
  DOWN = 'd',
  BETWEEN = 'b'
}

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  private observables: Record<string, Observable<boolean>> = {};
  public isMobile$: Observable<boolean>;

  constructor() {
    this.isMobile$ = this.isMobile();
  }

  private static media(query: string): Observable<boolean> {
    const mediaQuery = window.matchMedia(query);

    return fromEvent<MediaQueryListEvent>(mediaQuery, 'change').pipe(
      startWith(mediaQuery as MediaQueryListEvent | MediaQueryList),
      map((event: MediaQueryListEvent | MediaQueryList) => {
        return ('matches' in event) ? event.matches : (event as MediaQueryList).matches;
      }),
    );
  }

  public up(width: number): Observable<boolean> {
    if (!this.observables[DIR.UP + width]) {
      this.observables[DIR.UP + width] = MediaQueryService.media(`(min-width: ${width}px)`);
    }
    return this.observables[DIR.UP + width];
  }

  public down(width: number): Observable<boolean> {
    if (!this.observables[DIR.DOWN + width]) {
      this.observables[DIR.DOWN + width] = MediaQueryService.media(`(max-width: ${width}px)`);
    }
    return this.observables[DIR.DOWN + width];
  }

  public between(min: number, max: number): Observable<boolean> {
    if (!this.observables[DIR.BETWEEN + min + max]) {
      this.observables[DIR.BETWEEN + min + max] = MediaQueryService.media(`(min-width: ${min}px) and (max-width: ${max}px)`);
    }
    return this.observables[DIR.BETWEEN + min + max];
  }

  public isMobile(): Observable<boolean> {
    return this.down(992);
  }
}
