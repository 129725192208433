<section class="modal-container single-experience">
  <mat-dialog-content>
    <button class="close-button" mat-dialog-close (click)="close()">
      &times;
    </button>
    <h2 class="m-0">{{ data.title }}</h2>
    <div class="d-flex single-experience__content">
      @if ((mediaQueryService.up(1500) | async)) {
        <div class="w-30 d-flex flex-column gap-6 single-experience__content-left">
          <p class="text-subtitle">{{data.subtitle}}</p>
          <div class="box">
            <img [src]="'assets/images/world.svg'">
            <p [innerHtml]="data.localization"></p>
          </div>
          <div class="box">
            <img [src]="'assets/images/briefcase.svg'">
            <p class="font-bold" [innerHtml]="data.for"></p>
          </div>
          <div class="box-icon flex-column">
            <div class="box">
              <img [src]="'assets/images/tasks.svg'">
              <p class="font-bold" translate>basic.tasks</p>
            </div>
            <div class="icon-container">
              <div class="icon-row">
                @if (data.icon[0].icon1) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon1.url" alt="icon1">
                    <p>{{ data.icon[0].description1 }}</p>
                  </div>
                }
                @if (data.icon[0].icon2) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon2.url" alt="icon2">
                    <p>{{ data.icon[0].description2 }}</p>
                  </div>
                }
              </div>
              <div class="icon-row">
                @if (data.icon[0].icon3) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon3.url" alt="icon2">
                    <p>{{ data.icon[0].description3 }}</p>
                  </div>
                }
                @if (data.icon[0].icon4) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon4.url" alt="icon2">
                    <p>{{ data.icon[0].description4 }}</p>
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="box">
            <img [src]="'assets/images/calendar.svg'">
            <p class="font-bold">{{data.when}}</p>
          </div>
          <div class="box">
            <img [src]="'assets/images/target.svg'">
              @if (clientNeedsHtml) {
                  <p [innerHtml]="clientNeedsHtml"></p>
              }
          </div>
        </div>
      } @else {
        <div class="w-30 d-flex flex-column gap-6 single-experience__content-left small">
          <p class="text-subtitle">{{data.subtitle}}</p>
          <div class="d-flex align-items-start small-container">
            <div class="w-50 small-column">
              <div class="box">
                <img [src]="'assets/images/world.svg'">
                <p [innerHtml]="data.localization"></p>
              </div>
              <div class="box">
                <img [src]="'assets/images/calendar.svg'">
                <p class="font-bold">{{data.when}}</p>
              </div>
            </div>
            <div class="w-50 small-column">
              <div class="box">
                <img [src]="'assets/images/briefcase.svg'">
                <p class="font-bold" [innerHtml]="data.for"></p>
              </div>
              <div class="box">
                <img [src]="'assets/images/target.svg'">
                <p [innerHtml]="data.clientNeeds"></p>
              </div>
            </div>
          </div>
          <div class="box-icon flex-column w-100">
            <div class="box">
              <img [src]="'assets/images/tasks.svg'">
              <p class="font-bold" translate>basic.tasks</p>
            </div>
            <div class="icon-container d-flex">
              <div class="icon-column">
                @if (data.icon[0].icon1) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon1.url" alt="icon1">
                    <p>{{ data.icon[0].description1 }}</p>
                  </div>
                }
                @if (data.icon[0].icon2) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon2.url" alt="icon2">
                    <p>{{ data.icon[0].description2 }}</p>
                  </div>
                }
              </div>
              <div class="icon-column">
                @if (data.icon[0].icon3) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon3.url" alt="icon2">
                    <p>{{ data.icon[0].description3 }}</p>
                  </div>
                }
                @if (data.icon[0].icon4) {
                  <div class="icon-item">
                    <img [src]="data.icon[0].icon4.url" alt="icon2">
                    <p>{{ data.icon[0].description4 }}</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
      <div class="w-70 thumbnail">
        @if (data.media || data.mediaurl) {
          <div class="video-container w-100">
            <div [class.loaded]="videoLoaded$ | async" class="video-wrapper">
              <div *ngIf="(videoLoaded$ | async) === false" class="loader-wrapper">
                <mat-spinner/>
              </div>
              <video (loadeddata)="onVideoLoaded()" playsinline autoplay loop muted>
                @if (data.media && data.media.url) {
                  <source [src]="data.media.video?.mp4Url" type="video/mp4" />
                } @else if (data.mediaurl) {
                  <source [src]="'assets/media/content/' + data.mediaurl" type="video/mp4" />
                }
              </video>
            </div>
          </div>
        }
      </div>
    </div>
  </mat-dialog-content>
</section>