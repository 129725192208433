<div class="media-container">
    <img class="w-100" *ngIf="hasImage()" [src]="getSelectedMedia()?.image" alt="Media" />
    <video class="w-100" width="663" height="671" *ngIf="hasVideo()" [src]="getSelectedMedia()?.video" controls playsinline autoplay loop muted></video>
    <div *ngIf="hasLink()" class="sketchfab-embed-wrapper">
        <iframe class="responsive-iframe" [src]="getSanitizedUrl(getSelectedMedia()?.link)" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel=""></iframe>
    </div>
</div>

<div class="switcher">
  @if (getMediaSourceLength() > 1) {
    @if(getMediaSource().options?.[1]) {
      <input type="radio" id="option1" name="mediaOption" value="1" [checked]="selectedModel === 1" (change)="selectedModel = 1">
      <label for="option1" [innerHTML]="getMediaSource().options?.[1]?.label! | translate"></label>
    }
    @if(getMediaSource().options?.[2]) {
      <input type="radio" id="option2" name="mediaOption" value="2" [checked]="selectedModel === 2" (change)="selectedModel = 2">
      <label for="option2" [innerHTML]="getMediaSource().options?.[2]?.label! | translate"></label>
    }
    @if(getMediaSource().options?.[3]) {
      <input type="radio" id="option3" name="mediaOption" value="3" [checked]="selectedModel === 3" (change)="selectedModel = 3">
      <label for="option3" [innerHTML]="getMediaSource().options?.[3]?.label! | translate"></label>
    }
  }
</div>

@if (getMediaSourceLength() > 1 && selectedModel) {
  <p [innerHTML]="'main.' + type + '.' + selectedModel + '.content' | translate"></p>
}