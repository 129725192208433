<div class="single-article" *ngIf="article">
  <h1 class="text-left">{{ article.title }}</h1>
  <div *ngFor="let block of article.content">
    <ng-container *ngIf="block.__typename === 'ParagraphRecord'">
      <markdown [data]="block.content"></markdown>
    </ng-container>

    <ng-container *ngIf="block.__typename === 'TwoLayoutModelLeftImageRecord' || block.__typename === 'TwoLayoutModelRightImageRecord'">
      <div class="d-flex gap-24 two-column-layout">
        <div class="d-flex flex-column w-50 expanded-image-container" *ngIf="block.__typename === 'TwoLayoutModelLeftImageRecord'">
          <img *ngIf="block.image" [src]="block.image.url" [alt]="block.imageDescription || 'Image description not available'">
          <p>{{block.imageDescription}}</p>
        </div>
        <div class="d-flex flex-column w-50">
          <ng-container *ngFor="let child of block.content?.value.document.children">
            <ng-container [ngSwitch]="child.type">
              <ng-container *ngSwitchCase="'paragraph'">
                <p [innerHTML]="renderContent(child.children)"></p>
              </ng-container>
              <ng-container *ngSwitchCase="'heading'">
                <ng-container [ngSwitch]="child.level">
                  <h1 *ngSwitchCase="1" [innerHTML]="renderContent(child.children)"></h1>
                  <h2 *ngSwitchCase="2" [innerHTML]="renderContent(child.children)"></h2>
                  <h3 *ngSwitchCase="3" [innerHTML]="renderContent(child.children)"></h3>
                  <h4 *ngSwitchCase="4" [innerHTML]="renderContent(child.children)"></h4>
                  <h5 *ngSwitchCase="5" [innerHTML]="renderContent(child.children)"></h5>
                  <h6 *ngSwitchCase="6" [innerHTML]="renderContent(child.children)"></h6>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'list'">
                <ul *ngIf="child.style === 'bulleted'">
                  <li *ngFor="let item of child.children">
                    <p [innerHTML]="renderContent(item.children)"></p>
                  </li>
                </ul>
                <ol *ngIf="child.style === 'ordered'">
                  <li *ngFor="let item of child.children">
                    <p [innerHTML]="renderContent(item.children)"></p>
                  </li>
                </ol>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="d-flex flex-column w-50 expanded-image-container" *ngIf="block.__typename === 'TwoLayoutModelRightImageRecord'">
          <img *ngIf="block.image" [src]="block.image.url" [alt]="block.imageDescription || 'Image description not available'">
          <p>{{block.imageDescription}}</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="block.__typename === 'ModelArticleRecord'">
      <div class="sketchfab-embed-wrapper">
        @if (block.url) {
          <iframe width="100%" height="100%" [src]="getSanitizedUrl(block.url)" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel=""></iframe>
        }
      </div>
    </ng-container>
    <ng-container *ngIf="block.__typename === 'SingleImageRecord'">
      @if (block.image?.url) {
        <div class="image-container">
          <img [src]="block.image?.url" alt=""/>
        </div>
      }
    </ng-container>
  </div>

  @if ((mediaQueryService.up(900) | async)) {
    <div class="contact-banner-buttons contact-banner-buttons__footer">
      <a [routerLink]="['/article', prevArticleId]" *ngIf="prevArticleId">{{ 'common.previous' | translate }}</a>
      <a [routerLink]="'/'">{{ 'common.main' | translate }}</a>
      <a [routerLink]="['/article', nextArticleId]" *ngIf="nextArticleId">{{ 'common.next' | translate }}</a>
    </div>
  } @else {
    <div class="contact-banner-buttons contact-banner-buttons__footer">
      <a [routerLink]="['/article', prevArticleId]" *ngIf="prevArticleId">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#FAD829"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-left"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l6 6" /><path d="M5 12l6 -6" /></svg>
      </a>
      <a [routerLink]="'/'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#FAD829" class="icon icon-tabler icons-tabler-filled icon-tabler-home"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12.707 2.293l9 9c.63 .63 .184 1.707 -.707 1.707h-1v6a3 3 0 0 1 -3 3h-1v-7a3 3 0 0 0 -2.824 -2.995l-.176 -.005h-2a3 3 0 0 0 -3 3v7h-1a3 3 0 0 1 -3 -3v-6h-1c-.89 0 -1.337 -1.077 -.707 -1.707l9 -9a1 1 0 0 1 1.414 0m.293 11.707a1 1 0 0 1 1 1v7h-4v-7a1 1 0 0 1 .883 -.993l.117 -.007z"/></svg>
      </a>
      <a [routerLink]="['/article', nextArticleId]" *ngIf="nextArticleId">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#FAD829"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M13 18l6 -6" /><path d="M13 6l6 6" /></svg>
      </a>
    </div>
  }

</div>
<app-footer [isArticle]="true"/>