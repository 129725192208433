<div class="container mb-50 mt-90">
  <h1>Polityka prywatności</h1>
  <p>Polityka prywatności strony internetowej: www.bimmonuments.pl</p>

  <h2>1. POSTANOWIENIA OGÓLNE</h2>
  <p>1. Administratorem danych osobowych zbieranych za pośrednictwem strony internetowej www.bimmonuments.pl jest firma 3D+I Sp. Z O.O. adres siedziby: ul. Zamknięta 10/1.5; 30-554 Kraków; NIP: 6793231216, REGON: 521010860, wpisaną do Krajowego Rejestru Sądowego pod numerem KRS: 0000948331, adres poczty elektronicznej: <a href="mailto:info@bimmonuments.pl">info&#64;bimmonuments.pl</a>, dalej „Administrator”, będący/a jednocześnie Usługodawcą. , miejsce wykonywania działalności: ul. Zamknięta 10/1.5; 30-554 Kraków, adres poczty elektronicznej (e-mail): <a href="mailto:info@bimmonuments.pl">info&#64;bimmonuments.pl</a>, zwany/a dalej „Administratorem”.</p>
  <p>2. Dane osobowe zbierane przez Administratora za pośrednictwem strony internetowej są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane dalej RODO oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018 r.</p>

  <h2>2. RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I ZAKRES ZBIERANIA DANYCH</h2>

  <h3>1. CEL PRZETWARZANIA I PODSTAWA PRAWNA</h3>
  <p>Administrator przetwarza dane osobowe za pośrednictwem strony www.bimmonuments.pl w przypadku:</p>
  <ul>
    <li>skorzystania przez użytkownika z formularza kontaktowego. Dane osobowe są przetwarzane na podstawie art. 6 ust. 1 lit. f) RODO jako prawnie usprawiedliwiony interes Administratora.</li>
    <li>zapisania się przez użytkownika do Newslettera w celu przesyłania informacji handlowych drogą elektroniczną. Dane osobowe są przetwarzane po wyrażeniu odrębnej zgody, na podstawie art. 6 ust. 1 lit. a) RODO.</li>
  </ul>

  <h3>2. RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH</h3>
  <p>Administrator przetwarza następujące kategorie danych osobowych użytkownika:</p>
  <ul>
    <li>Imię i nazwisko,</li>
    <li>Adres e-mail,</li>
  </ul>

  <h3>3. OKRES ARCHIWIZACJI DANYCH OSOBOWYCH</h3>
  <p>Dane osobowe użytkowników przechowywane są przez Administratora:</p>
  <ul>
    <li>w przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy, tak długo, jak jest to niezbędne do wykonania umowy, a po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej – trzy lata.</li>
    <li>w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody przez okres czasu odpowiadający okresowi przedawnienia roszczeń jakie może podnosić Administrator i jakie mogą być podnoszone wobec niego. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej – trzy lata.</li>
  </ul>

  <p>4. Podczas korzystania ze strony internetowej mogą być pobierane dodatkowe informacje, w szczególności: adres IP przypisany do komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.</p>
  <p>5. Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym informacje o linkach i odnośnikach, w które zdecydują się kliknąć lub innych czynnościach, podejmowanych na stronie internetowej. Podstawą prawną tego rodzaju czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ułatwieniu korzystania z usług świadczonych drogą elektroniczną oraz na poprawie funkcjonalności tych usług.</p>
  <p>6. Podanie danych osobowych przez użytkownika jest dobrowolne.</p>
  <p>7. Dane osobowe będą przetwarzane także w sposób zautomatyzowany w formie profilowania, o ile użytkownik wyrazi na to zgodę na podstawie art. 6 ust. 1 lit. a) RODO. Konsekwencją profilowania będzie przypisanie danej osobie profilu w celu podejmowania dotyczących jej decyzji bądź analizy lub przewidywania jej preferencji, zachowań i postaw.</p>
  <p>8. Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że zbierane przez niego dane są:</p>
  <ul>
    <li>przetwarzane zgodnie z prawem,</li>
    <li>zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami,</li>
    <li>merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.</li>
  </ul>

  <h2>3. UDOSTĘPNIENIE DANYCH OSOBOWYCH</h2>
  <p>1. Dane osobowe użytkowników przekazywane są dostawcom usług, z których korzysta Administrator przy prowadzeniu strony internetowej. Dostawcy usług, którym przekazywane są dane osobowe, w zależności od uzgodnień umownych i okoliczności, albo podlegają poleceniom Administratora co do celów i sposobów przetwarzania tych danych (podmioty przetwarzające) albo samodzielnie określają cele i sposoby ich przetwarzania (administratorzy).</p>
  <p>2. Dane osobowe użytkowników są przechowywane wyłącznie na terenie Europejskiego Obszaru Gospodarczego (EOG).</p>

  <h2>4. PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ ICH POPRAWIANIA</h2>
  <p>1. Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</p>
  <p>2. Podstawy prawne żądania użytkownika:</p>
  <ul>
    <li>Dostęp do danych – art. 15 RODO</li>
    <li>Sprostowanie danych – art. 16 RODO</li>
    <li>Usunięcie danych (tzw. prawo do bycia zapomnianym) – art. 17 RODO</li>
    <li>Ograniczenie przetwarzania – art. 18 RODO</li>
    <li>Przeniesienie danych – art. 20 RODO</li>
    <li>Sprzeciw – art. 21 RODO</li>
    <li>Cofnięcie zgody – art. 7 ust. 3 RODO</li>
  </ul>
  <p>3. W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać stosowną wiadomość e-mail na adres: <a href="mailto:info@bimmonuments.pl">info&#64;bimmonuments.pl</a></p>
  <p>4. W sytuacji wystąpienia przez użytkownika z uprawnieniem wynikającym z powyższych praw, Administrator spełnia żądanie albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu miesiąca po jego otrzymaniu. Jeżeli jednak – z uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator nie będzie mógł spełnić żądania w ciągu miesiąca, spełni je w ciągu kolejnych dwóch miesięcy informując użytkownika uprzednio w terminie miesiąca od otrzymania żądania – o zamierzonym przedłużeniu terminu oraz jego przyczynach.</p>
  <p>5. W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO, osoba, której dane dotyczą, ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.</p>

  <h2>5. PLIKI „COOKIES”</h2>
  <p>1. Strona Administratora używa plików „cookies”.</p>
  <p>2. Instalacja plików „cookies” jest konieczna do prawidłowego świadczenia usług na stronie internetowej. W plikach „cookies” znajdują się informacje niezbędne do prawidłowego funkcjonowania strony, a także dają one także możliwość opracowywania ogólnych statystyk odwiedzin strony internetowej.</p>
  <p>3. W ramach strony stosowane są rodzaje plików „cookies”:</p>
  <p>4. Administrator wykorzystuje własne pliki cookies w celu lepszego poznania sposobu interakcji użytkownika w zakresie zawartości strony. Pliki gromadzą informacje o sposobie korzystania ze strony internetowej przez użytkownika, typie strony, z jakiej użytkownik został przekierowany oraz liczbie odwiedzin i czasie wizyty użytkownika na stronie internetowej. Informacje te nie rejestrują konkretnych danych osobowych użytkownika, lecz służą do opracowania statystyk korzystania ze strony.</p>
  <p>5. Użytkownik ma prawo zadecydowania w zakresie dostępu plików „cookies” do swojego komputera poprzez ich uprzedni wybór w oknie swojej przeglądarki. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</p>

  <h2>6. POSTANOWIENIA KOŃCOWE</h2>
  <p>1. Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.</p>
  <p>2. Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.</p>
  <p>3. W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa polskiego.</p>
</div>


<app-footer/>