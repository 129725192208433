<section class="footer-container container">
  <div class="footer-content gap-center">
    <div class="footer-content-left w-50">
      <h3 class="footer-content-left__title" translate>footer.companyDetailsTitle</h3>
      @if ((mediaQueryService.up(700) | async)) {
        <div class="footer-content-container yellow-background w-100">
          <div class="d-flex flex-column w-100 justify-content-between gap-24">
            <div class="d-flex justify-content-between align-items-center footer-content-container__item">
              <div class="company-details">
                <p [innerHTML]="'footer.companyName' | translate"></p>
                <p translate>footer.companyAddress1</p>
                <p translate>footer.companyAddress2</p>

              </div>
              <div class="social-media">
                <a href="https://www.linkedin.com/company/bim-monuments/" target="_blank">
                  <img src="assets/images/linkedin.svg" alt="linkedin"/>
                </a>
                <a href="https://www.instagram.com/bimmonuments/" target="_blank">
                  <img src="assets/images/instagram.svg" alt="linkedin"/>
                </a>
                <a href="https://www.facebook.com/people/BIM-Monuments/100083173282817" target="_blank">
                  <img src="assets/images/facebook.svg" alt="linkedin"/>
                </a>
              </div>
            </div>
            <div class="d-flex justify-content-between footer-content-container__item  footer-content-container__item--bottom">
              <div>
                <p translate>footer.companyNIP</p>
                <p translate>footer.companyKRS</p>
              </div>
              <div>
                <h4 translate>footer.companyHeadquarters</h4>
                <p translate>footer.companyHeadquartersAddress1</p>
              </div>
              <div class="text-right footer-content-container__item--right relative">
                @if (isArticle) {
                  <p class="text-left font-weight-bold paragraph" translate>common.questions.text1</p>
                }
                <p>
                  <a href="mailto:{{ 'footer.email1' | translate }}">{{ 'footer.email1' | translate }}</a>
                  &nbsp;
                  <a href="mailto:{{ 'footer.email2' | translate }}">{{ 'footer.email2' | translate }}</a>
                </p>
                <p>
                  <a target="_blank" href="https://wa.me/{{ 'footer.phone1whats' | translate }}">{{ 'footer.phone1' | translate }}</a>
                  &nbsp;
                  <a target="_blank" href="tel:{{ 'footer.phone2whats' | translate }}">{{ 'footer.phone2' | translate }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      } @else {
        <div class="footer-content-container-small yellow-background w-100">
          <div class="d-flex flex-column w-100 justify-content-between gap-24">

            <div class="d-flex justify-content-left align-items-center">
              <div>
                <p [innerHTML]="'footer.companyName' | translate"></p>
                <p translate>footer.companyAddress1</p>
                <p translate>footer.companyAddress2</p>
              </div>
            </div>

            <div class="d-flex flex-wrap gap-6 justify-content-between align-items-center">
              <div>
                <p translate>footer.companyNIP</p>
                <p translate>footer.companyKRS</p>
              </div>
              <div class="d-flex flex-column">
                <a href="mailto:{{ 'footer.email1' | translate }}">{{ 'footer.email1' | translate }}</a>
                <a target="_blank" href="tel:{{ 'footer.phone2whats' | translate }}">{{ 'footer.phone2' | translate }}</a>
              </div>
            </div>

            <div class="d-flex flex-wrap gap-6 justify-content-between align-items-center">
              <div>
                <h4 translate>footer.companyHeadquarters</h4>
                <p translate>footer.companyHeadquartersAddress1</p>
              </div>
              <div class="social-media">
                <a href="https://www.linkedin.com/company/bim-monuments/" target="_blank">
                  <img src="assets/images/linkedin.svg" alt="linkedin"/>
                </a>
                <a href="https://www.instagram.com/bimmonuments/" target="_blank">
                  <img src="assets/images/instagram.svg" alt="linkedin"/>
                </a>
                <a href="https://www.facebook.com/people/BIM-Monuments/100083173282817" target="_blank">
                  <img src="assets/images/facebook.svg" alt="linkedin"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
    <div class="footer-content-right w-50">
      <h3 translate>footer.rightTitle</h3>
      <div class="d-flex justify-content-around align-items-center gap-12 footer-content-right__container">
        <div class="d-flex flex-column footer-content-right__item gap-12">
          <img src="assets/images/dbd.svg" alt="">
          <span translate>footer.dbd</span>
        </div>
        <div class="d-flex flex-column footer-content-right__item gap-12 footer-content-right__item-right">
          <img src="assets/images/bim-monuments.svg" alt="">
          <span [innerHTML]="'footer.bimMonuments' | translate"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <p translate>footer.copyright</p>
    <a [routerLink]="'/privacy'" translate>footer.privacyPolicy</a>
  </div>
</section>
