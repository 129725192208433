import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SectionTypeEnum } from '../../../shared/model';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  public activeType$: BehaviorSubject<SectionTypeEnum> = new BehaviorSubject<SectionTypeEnum>(SectionTypeEnum.INVENTORY);
}
