<div class="home-view d-flex justify-content-left align-items-center">
  <div class="content">
    <div class="title">
      <h2 [innerHTML]="('home.title' | translate)"></h2>
      <p [innerHTML]="('home.subtitle' | translate)"></p>
    </div>
    <div class="buttons d-flex flex-column">
      <div>
        <a class="button" routerLink="/interactive">
          <span translate>home.interactive</span>
        </a>
        <span class="info-text" translate>home.interactive.info</span>
      </div>
      <a class="button button--basic" [routerLink]="['/']" fragment="main">
        <span translate>home.basic</span>
      </a>
    </div>
  </div>
  <div class="video-container">
    <div [class.loaded]="videoLoaded$ | async" class="video-wrapper">
      <div *ngIf="(videoLoaded$ | async) === false" class="loader-wrapper">
        <mat-spinner/>
      </div>
      <video (loadeddata)="onVideoLoaded()" playsinline autoplay loop muted>
        <source src="assets/videos/home.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
  <div class="copyright">
    <span>Copyright 2024 BIM Monuments  & 3D+I Sp. z . o.o.  | Polityka prywatności</span>
  </div>
</div>
<app-basic-view></app-basic-view>
