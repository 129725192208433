import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MediaQueryService } from '../../services/media-query.service';

@Component({
  standalone: true,
  selector: 'app-footer',
  imports: [
    TranslateModule,
    CommonModule,
    MatIconModule,
    RouterLink
  ],
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  mediaQueryService = inject(MediaQueryService);

  @Input() public isArticle: boolean = false;
}