<div class="error-page">
  <div class="error-container">
    <p [innerHTML]="'common.errorPage' | translate"></p>
    <div class="basic-link-container">
      <a [routerLink]="['/']" fragment="main" class="basic-version-link">
        <img src="assets/images/home.svg" alt="home-icon"/>
        <span [innerHTML]="'common.goToHome' | translate"></span>
      </a>
    </div>
  </div>
</div>