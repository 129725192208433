import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArticleNavigationService {
  private articlesSource = new BehaviorSubject<any[]>([]);

  setArticles(articles: Array<any>): void {
    if (articles) {
      const sortedArticles = [...articles].sort((a, b) => Number(a.index) - Number(b.index));

      this.articlesSource.next(sortedArticles);
    }
  }
}