import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatTabsModule } from '@angular/material/tabs';
import { SectionType } from '../../../../../../shared/model';

@Component({
  standalone: true,
  selector: 'app-partners',
  imports: [
    TranslateModule,
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    SlickCarouselModule
  ],
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent {
  @Input() public type: SectionType = 'inventory';

  public selectedTab: string = 'investors';

  public config: Array<SectionType> = [
    'inventory',
    'integration',
    'bim',
  ];

  selectTab(tab: string): void {
    this.selectedTab = tab;
  }
}