import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { EmailService } from '../../services/email.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MediaQueryService } from '../../services/media-query.service';

@Component({
  standalone: true,
  selector: 'app-contact-section',
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.scss'],
  providers: [
    EmailService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent implements OnInit {
  public mediaQueryService =  inject(MediaQueryService);
  private formBuilder = inject(FormBuilder);
  private emailService =  inject(EmailService);

  public contactForm!: FormGroup;
  public emailSend = signal<boolean>(false);
  public emailSending = signal<boolean>(false);

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      countryCode: ['PL', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{9,12}$')]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      consent: [false, Validators.requiredTrue]
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.emailSend.set(false);
      this.emailSending.set(true);
      this.emailService.sendEmail(this.contactForm.value).subscribe({
        next: (data) => {
          try {
            const parsedData = JSON.parse(data);
            if (parsedData.send === 'success') {
              this.emailSend.set(true);
            } else {
              console.error('Error in response:', parsedData.msg);
            }
          } catch (e) {
            console.error('Error parsing response:', e);
          }
          this.emailSending.set(false);
        },
        error: (error) => {
          console.error('Server error:', error);
          this.emailSending.set(false);
        },
      });
    }
  }
}
