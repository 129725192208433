import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { BasicComponent } from '../basic/basic.component';
import { BehaviorSubject } from 'rxjs';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { filter } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-home-view',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    RouterLink,
    BasicComponent,
    MatProgressSpinner
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HomeComponent implements AfterViewInit {
  videoLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);

  public onVideoLoaded() {
    this.videoLoaded$.next(true);
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activatedRoute.fragment.subscribe((fragment) => {
          if (fragment) {
            this.scrollToElement(fragment);
          }
        });
      });
  }

  private scrollToElement(fragment: string) {
    const element = document.getElementById(fragment);
    if (element) {
      const yOffset = -30; // Przesunięcie o 30px w górę
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
