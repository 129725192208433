@if ((realizationLoaded$ | async) === false) {
  <div class="big-loader">
    <mat-spinner/>
  </div>
} @else if (realization) {
  <div class="single-realization">
    <h2 class="m-0">{{ realization.title }}</h2>
    <div class="d-flex flex-column single-experience__content">
      <div class="w-100 thumbnail">
        @if (realization.media || realization.mediaurl) {
          <div class="video-container w-100">
            <div [class.loaded]="videoLoaded$ | async" class="video-wrapper">
              <div *ngIf="(videoLoaded$ | async) === false" class="loader-wrapper">
                <mat-spinner/>
              </div>
              <video (loadeddata)="onVideoLoaded()" playsinline autoplay loop muted>
                @if (realization.media && realization.media.url) {
                  <source [src]="realization.media.video?.mp4Url" type="video/mp4" />
                } @else if (realization.mediaurl) {
                  <source [src]="'assets/media/content/' + realization.mediaurl" type="video/mp4" />
                }
              </video>
            </div>
          </div>
        }
      </div>
      <div class="w-100 d-flex flex-column gap-6 single-experience__content-left">
        <p class="text-subtitle">{{realization.subtitle}}</p>
        <div class="d-flex align-items-start small-container gap-12">
          <div class="w-50 small-column">
            <div class="box">
              <img [src]="'assets/images/world.svg'">
              <p [innerHtml]="realization.localization"></p>
            </div>
            <div class="box">
              <img [src]="'assets/images/calendar.svg'">
              <p class="font-bold">{{realization.when}}</p>
            </div>
          </div>
          <div class="w-50 small-column">
            <div class="box">
              <img [src]="'assets/images/briefcase.svg'">
              <p class="font-bold" [innerHtml]="realization.for"></p>
            </div>
            <div class="box">
              <img [src]="'assets/images/target.svg'">
              <p [innerHtml]="realization.clientNeeds"></p>
            </div>
          </div>
        </div>
        <div class="box-icon flex-column w-100">
          <div class="box d-flex align-items-center">
            <img [src]="'assets/images/tasks.svg'">
            <p class="font-bold" translate>basic.tasks</p>
          </div>
          <div class="icon-container d-flex">
            <div class="icon-column">
              @if (realization.icon[0].icon1) {
                <div class="icon-item">
                  <img [src]="realization.icon[0].icon1.url" alt="icon1">
                  <p>{{ realization.icon[0].description1 }}</p>
                </div>
              }
              @if (realization.icon[0].icon2) {
                <div class="icon-item">
                  <img [src]="realization.icon[0].icon2.url" alt="icon2">
                  <p>{{ realization.icon[0].description2 }}</p>
                </div>
              }
            </div>
            <div class="icon-column">
              @if (realization.icon[0].icon3) {
                <div class="icon-item">
                  <img [src]="realization.icon[0].icon3.url" alt="icon2">
                  <p>{{ realization.icon[0].description3 }}</p>
                </div>
              }
              @if (realization.icon[0].icon4) {
                <div class="icon-item">
                  <img [src]="realization.icon[0].icon4.url" alt="icon2">
                  <p>{{ realization.icon[0].description4 }}</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    @if ((mediaQueryService.up(900) | async)) {-->
<!--      <div class="contact-banner-buttons contact-banner-buttons__footer">-->
<!--        <a [routerLink]="['/realization', prevRealizationId]" *ngIf="prevRealizationId">{{ 'common.previous' | translate }}</a>-->
<!--        <a [routerLink]="'/'">{{ 'common.main' | translate }}</a>-->
<!--        <a [routerLink]="['/realization', nextRealizationId]" *ngIf="nextRealizationId">{{ 'common.next' | translate }}</a>-->
<!--      </div>-->
<!--    } @else {-->
<!--      <div class="contact-banner-buttons contact-banner-buttons__footer">-->
<!--        <a [routerLink]="['/realization', prevRealizationId]" *ngIf="prevRealizationId">-->
<!--          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#FAD829"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-left"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M5 12l6 6" /><path d="M5 12l6 -6" /></svg>-->
<!--        </a>-->
<!--        <a [routerLink]="'/'">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#FAD829" class="icon icon-tabler icons-tabler-filled icon-tabler-home"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12.707 2.293l9 9c.63 .63 .184 1.707 -.707 1.707h-1v6a3 3 0 0 1 -3 3h-1v-7a3 3 0 0 0 -2.824 -2.995l-.176 -.005h-2a3 3 0 0 0 -3 3v7h-1a3 3 0 0 1 -3 -3v-6h-1c-.89 0 -1.337 -1.077 -.707 -1.707l9 -9a1 1 0 0 1 1.414 0m.293 11.707a1 1 0 0 1 1 1v7h-4v-7a1 1 0 0 1 .883 -.993l.117 -.007z"/></svg>-->
<!--        </a>-->
<!--        <a [routerLink]="['/realization', nextRealizationId]" *ngIf="nextRealizationId">-->
<!--          <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#FAD829"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l14 0" /><path d="M13 18l6 -6" /><path d="M13 6l6 6" /></svg>-->
<!--        </a>-->
<!--      </div>-->
<!--    }-->

  </div>
  <app-footer [isArticle]="true"/>
}