import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { DatocmsService } from '../../services/datocms.service';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ArticleNavigationService } from '../../services/article-navigation.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
    SlickCarouselModule
  ],
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogComponent implements OnInit {
  private datocmsService = inject(DatocmsService);
  private translateService = inject(TranslateService);
  private articleNavigationService = inject(ArticleNavigationService);

  public slickConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: true,
    dots: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          centerMode: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          centerMode: false,
        }
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
        }
      }
    ]
  };
  public articles$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public ngOnInit(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.loadArticles();
    });
    this.loadArticles();
  }

  private loadArticles(): void {
    const currentLang = this.translateService.currentLang || 'en';

    this.datocmsService.getAllArticles(currentLang).subscribe((result) => {
      let articles;

      switch (currentLang) {
        case 'pl':
          articles = result.data.allArticlepls;
          break;
        case 'de':
          articles = result.data.allArticledes;
          break;
        default:
          articles = result.data.allArticleens;
      }

      if (articles) {
        this.articles$.next(articles);
        this.articleNavigationService.setArticles(articles);
      }
    });
  }
}
