import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

type MediaOption = {
  image?: string;
  video?: string;
  link?: string;
  label?: string;
};

type MediaSource = {
  label?: string;
  image?: string;
  link?: string;
  video?: string;
  options?: Record<number, MediaOption>;
};

@Component({
  standalone: true,
  selector: 'app-hero-component',
  imports: [
    TranslateModule,
    CommonModule,
    MatButtonModule,
    SlickCarouselModule
  ],
  templateUrl: 'hero.component.html',
  styleUrls: ['hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent {
  private sanitizer = inject(DomSanitizer);

  public selectedModel: number = 1;
  private _type: string = 'main';

  @Input()
  set type(value: string) {
    this._type = value;
    this.onTypeChange(value);
  }

  get type(): string {
    return this._type;
  }

  onTypeChange(newType: string): void {
    this.selectedModel = 1;
  }

  public mediaSources: Record<string, MediaSource> = {
    model: {
      image: 'assets/images/logo.svg',
      label: 'MODEL',
      options: {
        1: { label: 'main.model.1.category', video: 'assets/media/model/model.mp4' },
        2: { label: 'main.model.2.category', link: 'https://sketchfab.com/models/6edb3810991b4362aa84d0dee78ffb09/embed' },
        3: { label: 'main.model.3.category', link: 'https://sketchfab.com/models/c0bd3356a4fe4135bce95a18fdb56790/embed' },
      }
    },
    scan: {
      label: 'PRZEGLĄDARKA SKANÓW',
      options: {
        1: { label: 'main.scan.1.category', link: 'https://sketchfab.com/models/6c601f7dd3ee4939bf4d8fbd5a445eea/embed' },
        2: { label: 'main.scan.2.category', video: 'assets/media/scan/scan1.mp4' },
        3: { label: 'main.scan.3.category', video: 'assets/media/scan/scan2.mp4' }
      }
    },
    it: {
      label: 'PRZEGLĄDARKA SKANÓW',
      options: {
        1: { label: 'main.scan.1.category', video: 'assets/media/it/it.mp4' },
      }
    },
    integration: {
      label: 'PRZEGLĄDARKA SKANÓW',
      options: {
        1: { label: 'main.scan.1.category', video: 'assets/media/integration/integration.mp4' },
      }
    },
    inventory: {
      options: {
        1: { label: 'main.scan.1.category', video: 'assets/media/inventory/inventory.mp4' },
      }
    },
    digitization: {
      label: 'PRZEGLĄDARKA SKANÓW',
      options: {
        1: { label: 'main.scan.1.category', video: 'assets/media/digitization/digitization.mp4' },
      }
    },
    bim: {
      label: 'PRZEGLĄDARKA SKANÓW',
      options: {
        1: { label: 'main.scan.1.category', video: 'assets/media/bim/bim.mp4' },
      }
    },
  };

  public getMediaSource(): MediaSource {
    return this.mediaSources[this.type];
  }

  public getMediaSourceLength(): number {
    const options = this.mediaSources?.[this.type]?.options;

    return options ? Object.keys(options).length : 0;
  }

  public getSelectedMedia(): MediaOption | MediaSource {
    const mediaSource = this.getMediaSource();

    if (mediaSource.options && this.selectedModel in mediaSource.options) {
      return mediaSource.options[this.selectedModel];
    }
    return mediaSource;
  }

  public hasImage(): boolean {
    const media = this.getSelectedMedia();
    return !!media.image;
  }

  public hasVideo(): boolean {
    const media = this.getSelectedMedia();
    return !!media.video;
  }

  public hasLink(): boolean {
    const media = this.getSelectedMedia();
    return !!media.link;
  }

  public getSanitizedUrl(url: string = ''): SafeResourceUrl | string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url) || url;
  }
}
