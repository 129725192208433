import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterComponent } from '../basic/sections/footer/footer.component';

@Component({
  standalone: true,
  selector: 'app-privacy-view',
  templateUrl: `./privacy.component.html`,
  imports: [
    FooterComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PrivacyComponent {
}
