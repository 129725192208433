<section *ngIf="articles$ | async as articles">
  <div class="articles-wrapper w-100">
    <h2 translate class="title-section container">basic.articles.title</h2>
  </div>
  <ngx-slick-carousel class="articles container" [config]="slickConfig">
    @for (article of articles; track article.id) {
      @if (article.adresUrl) {
        <div ngxSlickItem>
          <a class="article-container d-flex flex-column" [routerLink]="['/article', article.adresUrl]">
            <div class="article">
              <img [src]="article.image.url" alt="Article Image" class="article-image">
              <div class="image-caption">{{ article.title }}</div>
            </div>
            <h3 class="article-lead">{{ article.lead }}</h3>
          </a>
        </div>
      }
    }
  </ngx-slick-carousel>
</section>
