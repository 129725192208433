import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  standalone: true,
  selector: 'app-about-section',
  imports: [
    TranslateModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    SlickCarouselModule
  ],
  templateUrl: 'about.component.html',
  styleUrl: 'about.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AboutComponent {
  public scrollToBottom(): void {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }
}
