import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { Cacheable } from './cacheable.decorator';

@Injectable({
  providedIn: 'root'
})
export class DatocmsService {
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink
  ) {
    const headers = new HttpHeaders().set('Authorization', `Bearer 47a622b54634a2916584897b46c56d`);

    this.apollo.create({
      link: this.httpLink.create({
        uri: 'https://graphql.datocms.com/',
        headers: headers
      }),
      cache: new InMemoryCache()
    });
  }

  @Cacheable((args: any[]) => `articles-${args[0]}`)
  public getAllArticles(language: string): Observable<any> {
    const query = this.buildArticlesQuery(language);

    return this.apollo.watchQuery<any>({
      query: query
    }).valueChanges.pipe(
      catchError(err => {
        console.error('Error in getAllArticles:', err);
        throw err;
      })
    );
  }

  @Cacheable((args: any[]) => `realizations-${args[0]}`)
  public getAllRealizations(language: string): Observable<any> {
    const query = this.buildRealizationQuery(language);
    return this.apollo.watchQuery<any>({
      query: query
    }).valueChanges.pipe(
      catchError(err => {
        console.error('Error in getAllRealizations:', err);
        throw err;
      })
    );
  }

  @Cacheable((args: any[]) => `article-${args[1]}-${args[0]}`)
  getArticleById(adresUrl: string, language: string): Observable<any> {
    const query = this.buildArticleQueryByAdresUrl(language);

    return this.apollo.watchQuery<any>({
      query: query,
      variables: { adresUrl }
    }).valueChanges;
  }

  @Cacheable((args: any[]) => `realization-${args[1]}-${args[0]}`)
  getRealizationById(adresUrl: string, language: string): Observable<any> {
    const query = this.getRealizationByAdresUrl(language);

    return this.apollo.watchQuery<any>({
      query: query,
      variables: { adresUrl }
    }).valueChanges;
  }

  private getRealizationCollection(language: string): string {
    switch (language) {
      case 'pl':
        return 'allRealizationenpls';
      case 'de':
        return 'allRealizationdes';
      default:
        return 'allRealizationens';
    }
  }

  private getArticleCollectionByAdresUrl(language: string): string {
    switch (language) {
      case 'pl':
        return 'allArticlepls';
      case 'de':
        return 'allArticledes';
      default:
        return 'allArticleens';
    }
  }

  private getArticlesCollection(language: string): string {
    switch (language) {
      case 'pl':
        return 'allArticlepls';
      case 'de':
        return 'allArticledes';
      default:
        return 'allArticleens';
    }
  }

  private buildArticleQueryByAdresUrl(language: string): any {
    const collectionName = this.getArticleCollectionByAdresUrl(language);

    return gql`
    query getArticle($adresUrl: String!) {
      ${collectionName}(filter: { adresUrl: { eq: $adresUrl } }) {
        id
        index
        title
        lead
        adresUrl
        image {
          url
          alt
          title
        }
        content {
          ... on ParagraphRecord {
            id
            content(markdown: true)
          }
          ... on ModelArticleRecord {
            url
          }
          ... on MediaArticleRecord {
            mediaUrl
          }
          ... on SingleImageRecord {
            image {
              url
            }
          }
          ... on TwoLayoutModelLeftImageRecord {
            id
            content {
              blocks
              links
              value
            }
            image {
              url
              video {
                mp4Url
              }
            }
            imageDescription
          }
          ... on TwoLayoutModelRightImageRecord {
            id
            image {
              url
              alt
              title
              video {
                mp4Url
              }
            }
            imageDescription
            content {
              blocks
              links
              value
            }
          }
          ... on WithIconRecord {
            id
            icon1text
            icon3text
            icon2text
            icon3 {
              url
            }
            icon1 {
              url
            }
            icon2 {
              url
            }
          }
        }
      }
    }
  `;
  }

  private getRealizationByAdresUrl(language: string): any {
    const collectionName = this.getRealizationCollection(language);

    return gql`
    query getRealization($adresUrl: String!) {
      ${collectionName}(filter: { adresUrl: { eq: $adresUrl } }) {
        category {
          name
          id
        }
        title
        subtitle
        index
        thumbnail {
          url
          video {
            mp4Url
          }
        }
        media {
          url
          video {
            mp4Url
          }
        }
        mediaurl
        localization(markdown: true)
        for
        icon {
          description1
          description2
          description3
          description4
          icon1 {
            url
          }
          icon2 {
            url
          }
          icon3 {
            url
          }
          icon4 {
            url
          }
        }
        when
        clientNeeds(markdown: true)
      }
    }
  `;
  }


  private buildRealizationQuery(language: string) {
    const collectionName = this.getRealizationCollection(language);
    return gql`
    query getRealization {
      ${collectionName} {
        category {
          name
          id
        }
        title
        subtitle
        index
        adresUrl
        thumbnail {
          url
          video {
            mp4Url
          }
        }
        media {
          url
          video {
            mp4Url
          }
        }
        mediaurl
        localization(markdown: true)
        for
        icon {
          description1
          description2
          description3
          description4
          icon1 {
            url
          }
          icon2 {
            url
          }
          icon3 {
            url
          }
          icon4 {
            url
          }
        }
        when
        clientNeeds(markdown: true)
      }
    }
  `;
  }

  private buildArticlesQuery(language: string): any {
    const collectionName = this.getArticlesCollection(language);
    return gql`
    query GetAllArticles {
      ${collectionName} {
        id
        index
        title
        lead
        adresUrl
        image {
          url
          alt
          title
        }
        content {
          ... on ParagraphRecord {
            id
          }
          ... on ModelArticleRecord {
            url
          }
          ... on MediaArticleRecord {
            mediaUrl
          }
          ... on SingleImageRecord {
            image {
              url
            }
          }
          ... on TwoLayoutModelLeftImageRecord {
            id
          }
          ... on WithIconRecord {
            id
            icon1text
            icon3text
            icon2text
            icon3 {
              url
            }
            icon1 {
              url
            }
            icon2 {
              url
            }
          }
          ... on TwoLayoutModelRightImageRecord {
            id
            content {
              blocks
              links
              value
            }
          }
        }
      }
    }
  `;
  }
}