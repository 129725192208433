import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SectionTypeEnum } from '../model';
import { MainService } from '../../views/basic/services/main.service';

@Component({
  standalone: true,
  selector: 'app-navigation',
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    RouterLink
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  public mainService: MainService = inject(MainService);
  private translateService: TranslateService = inject(TranslateService);
  private router: Router = inject(Router);

  public activeLang!: string;
  public isInteractivePage$ = new BehaviorSubject(false);
  public menuOpen = false;
  public sectionKeys: Array<string> = [];
  protected readonly SectionTypeEnum = SectionTypeEnum;

  public ngOnInit() {
    this.sectionKeys = Object.keys(this.SectionTypeEnum) as Array<string>;

    const savedLang = localStorage.getItem('lang');

    if (savedLang) {
      this.activeLang = savedLang;
      this.translateService.use(this.activeLang);
    } else {
      this.activeLang = this.translateService.currentLang || this.translateService.getDefaultLang();
    }

    this.checkIfInteractivePage();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.checkIfInteractivePage();
      });
  }

  private checkIfInteractivePage(): void {
    const isInteractive = this.router.url.includes('/interactive');

    this.isInteractivePage$.next(isInteractive);
  }

  public switchLanguage(language: string) {
    this.translateService.use(language);
    this.activeLang = language;
    localStorage.setItem('lang', language);
    window.location.reload();
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  public changeActiveType(type: string) {
    const element = document.getElementById('main');
    const isOnMainPage = this.router.url === '/' || this.router.url.startsWith('/#');

    if (!isOnMainPage) {
      this.router.navigate(['/'], { fragment: 'main' }).then(() => {
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }

        this.mainService.activeType$.next(type as SectionTypeEnum);
      });
    } else {
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

      this.mainService.activeType$.next(type as SectionTypeEnum);
    }
    this.menuOpen = false;
  }
}