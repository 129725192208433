<section class="experience">
  <div class="section">
    <h2 class="title-section" translate>basic.experience</h2>
    <div class="title-section-backdrop" translate>basic.experience</div>
  </div>
  <div class="experience-wrapper">
    @if ((mediaQueryService.isMobile$ | async) === false) {
      <form [formGroup]="categoryForm">
        @if (categories.length) {
          <div class="options radio-container">
            @for (category of categories; track category) {
              <label class="radio-option">
                <input [value]="category.id" formControlName="selectedCategory" type="radio">
                <span class="custom-radio"></span>
                <span class="radio-text">{{ category.name }}</span>
              </label>
            }
          </div>
        }
      </form>
    }
    @if (isLoading()) {
      <mat-progress-spinner mode="indeterminate" />
    } @else {
      <ngx-slick-carousel class="carousel projects" [config]="slideConfig">
        @for (project of filteredRealizations; track project) {
          <div ngxSlickItem class="project-container">
            <button class="project"
                    (click)="openProjectDialog(project)">
              <p class="project-title">{{ project.title }}</p>
              <div class="image-container">
                @if (project.thumbnail.url) {
                  <img [src]="project.thumbnail.url" [alt]="project.title">
                } @else {
                  <div class="no-image"></div>
                }
              </div>
              @if (project.category) {
                <div class="categories">
                  @for (category of project.category; track category) {
                    <span class="project-category">{{ category.name }}
                      @if(!$last) {
                        <ng-container> | </ng-container>
                      }
                </span>
                  }
                </div>
              }
            </button>
          </div>
        }
      </ngx-slick-carousel>
    }
  </div>
</section>
