<section class="container about">
  <h2 translate class="title-section">basic.about.about_us</h2>
  <div class="about-container">
    <div class="w-50">
      <p class="description" translate>basic.about.about_us_description</p>
      <p class="content" [innerHTML]="'basic.about.about_us_content' | translate"></p>
      <p class="technology" [innerHTML]="'basic.about.technology' | translate"></p>
    </div>
    <div class="w-50">
      <p class="description" translate>basic.about.partners</p>
      <div class="partners-logos">
        <img src="assets/images/partners/img.png" alt="Partner 1">
        <img src="assets/images/partners/img_1.png" alt="Partner 2">
        <img src="assets/images/partners/img_2.png" alt="Partner 3">
        <img src="assets/images/partners/img_3.png" alt="Partner 4">
        <img src="assets/images/partners/img_4.png" alt="Partner 5">
        <img src="assets/images/partners/img_5.png" alt="Partner 6">
        <img src="assets/images/partners/img_6.png" alt="Partner 7">
        <img src="assets/images/partners/img_7.png" alt="Partner 8">
        <img src="assets/images/partners/img_8.png" alt="Partner 9">
        <img src="assets/images/partners/img_9.png" alt="Partner 10">
        <img src="assets/images/partners/img_10.png" alt="Partner 11">
        <img src="assets/images/partners/img_11.png" alt="Partner 12">
      </div>
      <div class="contact">
        <p translate>basic.about.meet_us</p>
        <button (click)="scrollToBottom()">
          <span translate>basic.about.meet_button</span>
        </button>
      </div>
<!--      <div class="projects">-->
<!--        <ngx-slick-carousel class="carousel"-->
<!--                            #slickModal="slick-carousel"-->
<!--                            [config]="slideConfig">-->
<!--          @for (slide of slides; track slide) {-->
<!--            <div ngxSlickItem class="slide">-->
<!--              <p class="font-bold" [translate]="'basic.about.carousel.' + $index + '.title'"></p>-->
<!--              <ul>-->
<!--                <li *ngFor="let elementIndex of [].constructor(slide.elements); let elIndex = index" translate>-->
<!--                  {{ 'basic.about.carousel.' + $index + '.' + (elIndex + 1) }}-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          }-->
<!--        </ngx-slick-carousel>-->
<!--      </div>-->
    </div>
  </div>
</section>