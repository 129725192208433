@if (config.includes(type)) {
  <div class="partners">
    <h2>{{ 'home.partners.sectionTitle' | translate }}</h2>
    <div class="tabs">
      @for (tab of ['investors', 'designers', 'contractors']; track tab) {
        <button [class.active]="selectedTab === tab"
                class="partners-tab"
                (click)="selectTab(tab)">
          {{ 'home.partners.tabs.' + tab | translate }}
        </button>
      }
    </div>
    <div class="content">
      <div *ngIf="selectedTab === 'investors'">
        <div class="logos">
          <img [src]="'assets/images/partners/main/' + type + '-investors.png'" alt="investors-logos">
        </div>
        <p class="and-more">{{ 'home.partners.andMore' | translate }}</p>
        <p>{{ 'home.partners.descriptions.investors' | translate }}</p>
      </div>
      <div *ngIf="selectedTab === 'designers'">
        <div class="logos">
          <img [src]="'assets/images/partners/main/' + type + '-designers.png'" alt="designers-logos">
        </div>
        <p class="and-more">{{ 'home.partners.andMore' | translate }}</p>
        <p>{{ 'home.partners.descriptions.designers' | translate }}</p>
      </div>
      <div *ngIf="selectedTab === 'contractors'">
        <div class="logos">
          <img [src]="'assets/images/partners/main/' + type + '-contractors.png'" alt="contractors-logos">
        </div>
        <p class="and-more">{{ 'home.partners.andMore' | translate }}</p>
        <p>{{ 'home.partners.descriptions.contractors' | translate }}</p>
      </div>
    </div>
  </div>
}