<section class="contact-form-container container">
  <h2>{{ 'basic.contact.title' | translate }}</h2>
  <form class="gap-center contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="form-left">
      <div class="d-flex gap-30 justify-content-between first-line">
        <div class="form-group">
          <label for="name">{{ 'basic.contact.name' | translate }}</label>
          <input type="text" id="name" formControlName="name" required>
          <div *ngIf="f['name'].touched && f['name'].invalid" class="error">
            {{ 'basic.contact.nameRequired' | translate }}
          </div>
        </div>
        <div class="form-group">
          <label for="phone">{{ 'basic.contact.phone' | translate }}</label>
          <div class="phone-input">
            <select formControlName="countryCode" class="country-code" required>
              <option value="PL">PL</option>
              <option value="EN">EN</option>
              <option value="DE">DE</option>
            </select>
            <input type="tel" id="phone" formControlName="phone" required class="phone-number" placeholder="{{ 'basic.contact.phone' | translate }}">
          </div>
          <div *ngIf="f['phone'].touched && f['phone'].invalid" class="error">
            <div *ngIf="f['phone'].errors?.['required']">{{ 'basic.contact.phoneRequired' | translate }}</div>
            <div *ngIf="f['phone'].errors?.['pattern']">{{ 'basic.contact.phoneInvalid' | translate }}</div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">{{ 'basic.contact.email' | translate }}</label>
          <input type="email" id="email" formControlName="email" required>
          <div *ngIf="f['email'].touched && f['email'].invalid" class="error">
            <div *ngIf="f['email'].errors?.['required']">{{ 'basic.contact.emailRequired' | translate }}</div>
            <div *ngIf="f['email'].errors?.['email']">{{ 'basic.contact.emailInvalid' | translate }}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label for="subject">{{ 'basic.contact.subject' | translate }}</label>
          <input type="text" id="subject" formControlName="subject" required>
          <div *ngIf="f['subject'].touched && f['subject'].invalid" class="error">
            {{ 'basic.contact.subjectRequired' | translate }}
          </div>
        </div>
        @if ((mediaQueryService.up(992) | async)) {
          <div class="form-group consent flex-column d-flex">
            <mat-checkbox
              class="example-margin"
              formControlName="consent">
              {{ 'basic.contact.consent' | translate }}
            </mat-checkbox>
            <div *ngIf="f['consent'].touched && f['consent'].invalid" class="error">
              {{ 'basic.contact.consentRequired' | translate }}
            </div>
          </div>
        }
      </div>
    </div>
    <div class="form-right">
      <div class="form-group">
        <label for="message">{{ 'basic.contact.message' | translate }}</label>
        <textarea id="message" formControlName="message" rows="5" required></textarea>
        <div *ngIf="f['message'].touched && f['message'].invalid" class="error">
          {{ 'basic.contact.messageRequired' | translate }}
        </div>
      </div>
      @if ((mediaQueryService.down(992) | async)) {
        <div class="form-group w-50 consent flex-column d-flex">
          <mat-checkbox
            class="example-margin"
            formControlName="consent">
            {{ 'basic.contact.consent' | translate }}
          </mat-checkbox>
          <div *ngIf="f['consent'].touched && f['consent'].invalid" class="error">
            {{ 'basic.contact.consentRequired' | translate }}
          </div>
        </div>
      }
      <div class="d-flex justify-content-between">
        <a class="phone-button" href="tel:{{ 'footer.phone2whats' | translate }}">
          <span translate>common.questions.callButton</span>
        </a>
        <button type="submit" class="submit-button" [disabled]="!contactForm.valid || emailSending()">{{ 'basic.contact.submit' | translate }}</button>
      </div>
      @if (emailSend()) {
        <p translate class="email-send">common.emailSend</p>
      }
    </div>
  </form>
</section>