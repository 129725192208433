@if ((isIframeLoaded | async) === false) {
  <div class="loading-container">
    <div class="loading-placeholder">
      <button class="close-button" (click)="close()">
        &times;
      </button>
      <a [routerLink]="['/']" fragment="main" class="basic-version-link">
        <img src="assets/images/home.svg" alt="home-icon"/>
        <span [innerHTML]="'common.goToHome' | translate"></span>
      </a>
    </div>
  </div>
}
<iframe
  [src]="iframeUrl"
  (load)="onIframeLoad()">
</iframe>
<div class="widget question-widget">
  <button class="widget-icon arrow-icons" (click)="toggleWidget()" [class.open]="isOpen">
    <img src="assets/images/arrow-left.svg" alt="Expand" />
  </button>
  <div class="widget-content" [class.open]="isOpen">
    <div class="widget-header">
      <p translate>common.question</p>
    </div>
    <div class="widget-icons">
      <a href="#contact">
        <img src="assets/images/chat-icon.png" alt="Czat" />
      </a>
      <a target="_blank" href="tel:{{ 'footer.phone2whats' | translate }}">
        <img src="assets/images/phone-icon.png" alt="Telefon" />
      </a>
      <a href="mailto:{{ 'footer.email1' | translate }}">
        <img src="assets/images/mail-icon.png" alt="Mail" />
      </a>
    </div>
  </div>
</div>

<div class="widget home-widget">
  <button class="widget-icon" (click)="toggleHomeWidget()" [class.open]="isHomeWidgetOpen">
    <img src="assets/images/home-blue.svg" alt="home" />
  </button>
  <a [routerLink]="['/']" fragment="main" class="widget-content" [class.open]="isHomeWidgetOpen">
    <p [innerHTML]="('common.basicPage' | translate)"></p>
  </a>
</div>
<div class="container footer-bottom">
  <p translate>footer.copyright</p>
  <div>
    <span>© 2024 3D+I Sp z o.o. | Wszelkie prawa zastrzeżone |</span>
    <a [routerLink]="'/privacy'" translate>footer.privacyPolicy</a>
  </div>
</div>