import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DatocmsService } from '../basic/services/datocms.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from '../basic/sections/contact/contact.component';
import { FooterComponent } from '../basic/sections/footer/footer.component';
import { MarkdownModule } from 'ngx-markdown';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { MediaQueryService } from '../basic/services/media-query.service';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {Project} from "../basic/sections/experience/model";
import {BehaviorSubject} from "rxjs";
import { Title } from '@angular/platform-browser';


@Component({
  standalone: true,
  selector: 'app-realization-detail',
  templateUrl: './realization-detail.component.html',
  styleUrls: ['./realization-detail.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    ContactComponent,
    FooterComponent,
    MarkdownModule,
    MatProgressSpinner
  ]
})
export class RealizationDetailComponent implements OnInit {
  public mediaQueryService = inject(MediaQueryService);
  public route = inject(ActivatedRoute);
  public datocmsService = inject(DatocmsService);
  public router = inject(Router);
  private translateService = inject(TranslateService);
  private sanitizer = inject(DomSanitizer);
  private title = inject(Title);

  public realization: Project | null = null;
  public prevRealizationId: string | null = null;
  public nextRealizationId: string | null = null;

  clientNeedsHtml: SafeHtml;
  videoLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  realizationLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const realizationId = params.get('id');
      const currentLang = this.translateService.currentLang || 'en';

      if (realizationId) {
        this.loadRealization(realizationId, currentLang);
        this.loadRealizationsAndSetNavigation(realizationId, currentLang);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }

  processClientNeeds(): void {
    if (this.realization && this.realization.clientNeeds) {
      const formattedContent = this.realization.clientNeeds
          .replace(/\n\*/g, '<li>')
          .replace(/\n/g, '')
          .replace(/\*\s/g, '<li>')
          .replace('<li>', '<ul><li>')
          .concat('</li></ul>');

      this.clientNeedsHtml = this.sanitizer.bypassSecurityTrustHtml(formattedContent);
    }
  }

  renderContent(children: any[]): string {
    return children.map((child: any) => {
      const classNames = this.getMarks(child.marks);

      if (child.type === 'span') {
        return child.marks && child.marks.includes('strong') ? `<strong>${child.value}</strong>` : child.value;
      } else if (child.type === 'list') {
        return this.renderList(child);
      } else if (child.type === 'paragraph') {
        return `<p class="${classNames}">${this.renderContent(child.children)}</p>`;
      } else if (child.type === 'heading') {
        const tag = `h${child.level}`;
        return `<${tag} class="${classNames}">${this.renderContent(child.children)}</${tag}>`;
      }
      return '';
    }).join('');
  }

  renderList(list: any): string {
    if (list.style === 'bulleted') {
      return `<ul>${list.children.map((item: any) => `<li>${this.renderContent(item.children)}</li>`).join('')}</ul>`;
    } else if (list.style === 'ordered') {
      return `<ol>${list.children.map((item: any) => `<li>${this.renderContent(item.children)}</li>`).join('')}</ol>`;
    }
    return '';
  }

  getMarks(marks: string[]): string {
    if (!marks) {
      return '';
    }
    return marks.map(mark => {
      switch (mark) {
        case 'strong':
          return 'font-weight-bold';
        case 'heading':
          return 'font-heading';
        case 'em':
          return 'font-italic';
        case 'underline':
          return 'text-underline';
        case 'code':
          return 'font-monospace';
        default:
          return '';
      }
    }).join(' ');
  }

  public onVideoLoaded() {
    this.videoLoaded$.next(true);
  }

  private loadRealization(id: string, lang: string): void {
    this.datocmsService.getRealizationById(id, lang).subscribe({
      next: (data) => {
        let result;

        switch (lang) {
          case 'pl':
            result = data.data.allRealizationenpls;
            break;
          case 'de':
            result = data.data.allRealizationdes;
            break;
          default:
            result = data.data.allRealizationens;
        }

        if (result && result.length > 0) {
          this.realization = result[0];
          this.processClientNeeds();
          this.realizationLoaded$.next(true);
          this.title.setTitle('BIM Monuments - ' + result[0].title);
        } else {
          this.router.navigate(['/realization', this.nextRealizationId]);
        }
      },
      error: (err) => {
        console.error('Error loading Realization:', err);
      }
    });
  }

  private loadRealizationsAndSetNavigation(currentRealizationAdresUrl: string, lang: string): void {
    this.datocmsService.getAllRealizations(lang).subscribe({
      next: (data) => {
        let realizations;
        switch (lang) {
          case 'pl':
            realizations = data.data.allRealizationenpls;
            break;
          case 'de':
            realizations = data.data.allRealizationdes;
            break;
          default:
            realizations = data.data.allRealizationens;
            break;
        }

        const currentIndex = realizations.findIndex((realization: any) => realization.adresUrl === currentRealizationAdresUrl);

        if (realizations.length > 0) {
          this.prevRealizationId = realizations[(currentIndex - 1 + realizations.length) % realizations.length]?.adresUrl;

          this.nextRealizationId = realizations[(currentIndex + 1) % realizations.length]?.adresUrl;
        }
      },
      error: (err) => {
        console.error('Error loading realizations for navigation:', err);
      }
    });
  }
}