import { Observable, of } from 'rxjs';

export function Cacheable(cacheKeyGenerator?: (args: any[]) => string): MethodDecorator {
  return (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    const cache = new Map<string, any>();

    descriptor.value = function(...args: any[]) {
      const key = cacheKeyGenerator ? cacheKeyGenerator(args) : JSON.stringify(args);

      if (cache.has(key)) {
        return of(cache.get(key));
      }

      const result = originalMethod.apply(this, args);

      if (result instanceof Observable) {
        result.subscribe(res => cache.set(key, res));
      } else {
        cache.set(key, result);
      }

      return result;
    };

    return descriptor;
  };
}
