import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FooterComponent } from '../basic/sections/footer/footer.component';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-privacy-view',
  templateUrl: `./error.component.html`,
  imports: [
    FooterComponent,
    RouterLink,
    TranslateModule
  ],
  styles: `
    :host {
      position: relative;
      height: 100vh;

      &::before {
        content: '';
        position: absolute;
        top: -69px;
        left: 0;
        width: 100%;
        height: calc(100% + 69px);
        background-image: url('/assets/images/background.jpeg');
        background-position: center;
        background-repeat: repeat-y;
        opacity: 0.15;
        z-index: -1;
      }
    }
    
    .error-page {
      height: 100vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      
      p {
        padding-left: 10vw;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 20px;
      }
    }
    
    .basic-link-container {
      background-color: #FAD829;
      padding: 35px 10vw 35px 10vw;;
    }

    .basic-version-link {
      padding: 10px 25px;
      font-size: 17px;
      background-color: #202D50;
      text-decoration: none;
      display: flex;
      align-items: center;
      line-height: 20px;
      gap: 20px;
      cursor: pointer;
      
      span {
        color: white;
        letter-spacing: 0.6px;
      }

      &:hover {
        outline: 2px solid #fff;

        span {
          color: #FAD829;
        }
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ErrorComponent {
}
