import { Component, inject, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { Project } from './model';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MediaQueryService } from '../../services/media-query.service';
import { MarkdownModule, SECURITY_CONTEXT, MarkdownService } from 'ngx-markdown';
import {DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'app-project-dialog',
  templateUrl: 'modal.component.html',
  imports: [
    MatDialogContent,
    AsyncPipe,
    MatProgressSpinner,
    NgIf,
    TranslateModule,
    MarkdownModule,
  ],
  providers: [
    { provide: SECURITY_CONTEXT, useValue: SecurityContext.NONE },
    MarkdownService,
  ],
  styleUrl: 'modal.component.scss',
})
export class ProjectDialogComponent implements OnInit {
  mediaQueryService = inject(MediaQueryService);
  sanitizer = inject(DomSanitizer);
  videoLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  clientNeedsHtml: SafeHtml;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Project,
    public dialogRef: MatDialogRef<ProjectDialogComponent>,
  ) {
  }

  ngOnInit(): void {
    this.processClientNeeds();
  }

  processClientNeeds(): void {
    if (this.data && this.data.clientNeeds) {
      const formattedContent = this.data.clientNeeds
          .replace(/\n\*/g, '<li>')
          .replace(/\n/g, '')
          .replace(/\*\s/g, '<li>')
          .replace('<li>', '<ul><li>')
          .concat('</li></ul>');

      this.clientNeedsHtml = this.sanitizer.bypassSecurityTrustHtml(formattedContent);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onVideoLoaded() {
    this.videoLoaded$.next(true);
  }
}