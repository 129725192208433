import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, timer } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';


@Component({
  standalone: true,
  selector: 'app-interactive-view',
  templateUrl: 'interactive.component.html',
  styleUrls: ['./interactive.component.scss'],
  imports: [
    TranslateModule,
    RouterLink,
    AsyncPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractiveComponent {
  private sanitizer = inject(DomSanitizer);
  private translateService = inject(TranslateService);

  public isIframeLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public iframeUrl: SafeResourceUrl;
  public isOpen = false;
  public isHomeWidgetOpen = false;

  constructor() {
    this.iframeUrl = this.getSanitizedUrl(this.translateService.instant('vista'));
  }

  public getSanitizedUrl(url: string = ''): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toggleWidget() {
    this.isOpen = !this.isOpen;
  }

  public onIframeLoad(): void {
    timer(8000).subscribe(() => {
      // this.isIframeLoaded.next(true);
    });
  }

  toggleHomeWidget() {
    this.isHomeWidgetOpen = !this.isHomeWidgetOpen;
  }

  close() {
    this.isIframeLoaded.next(true);
  }
}