<div class="navigation">
  <div class="navigation-container container d-flex justify-content-between align-items-center">
    <a [routerLink]="'/'" class="navigation-home nav-link" [class.interactive]="(isInteractivePage$ | async)">
      <img src="assets/images/logo.svg" alt="BIM Monuments Logo" class="logo"/>
    </a>
    <div class="d-flex align-items-center">

      <nav class="navbar d-none d-md-flex">
        <div class="nav-item dropdown">
          <a [routerLink]="['/']" fragment="main" class="nav-link active" id="mainNav" role="button" aria-haspopup="true" aria-expanded="false">
            <span translate>common.nav.main</span>
          </a>
          @if ((isInteractivePage$ | async) === false) {
            <div class="dropdown-menu" aria-labelledby="mainNav">
              @for (section of sectionKeys; track section) {
                <button class="dropdown-item"
                        (click)="changeActiveType(section)"
                        [class.active]="(mainService.activeType$ | async)?.toLowerCase() == section.toLowerCase()">
                  <span translate>{{ 'common.navServices.' + section.toLowerCase() }}</span>
                  @if (section === 'IT') {
                    <br/><br/>
                  }
                </button>
              }
            </div>
          }
        </div>
        <a [routerLink]="['/']" fragment="experience" class="nav-link">
          <span translate>common.nav.experience</span>
        </a>
        <a [routerLink]="['/']" fragment="news" class="nav-link">
          <span translate>common.nav.news</span>
        </a>
        <a [routerLink]="['/']" fragment="about" class="nav-link">
          <span translate>common.nav.about</span>
        </a>
        <a [routerLink]="['/']" fragment="contact" class="nav-link">
          <span translate>common.nav.contact</span>
        </a>
      </nav>
      <div class="language-switcher">
        <a mat-button href="https://bimmonuments.pl" [class.active]="activeLang === 'pl'" class="button">PL</a>
        <a mat-button href="https://bimmonuments.com" [class.active]="activeLang === 'en'" class="button">EN</a>
<!--        <button mat-button (click)="switchLanguage('de')" [class.active]="activeLang === 'de'" class="button">DE</button>-->
      </div>
      <button class="hamburger d-md-none" (click)="toggleMenu()">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </button>
    </div>
  </div>
  <div class="menu d-md-none" [class.open]="menuOpen">
    <nav class="navbar flex-column">
      <a [routerLink]="['/']" fragment="main" class="nav-link active" (click)="toggleMenu()">
        <span translate>common.nav.main</span>
      </a>
      <div class="menu-hamburger-sections">
        @for (section of sectionKeys; track section) {
          <button class="dropdown-item"
                  [class.active]="(mainService.activeType$ | async)?.toLowerCase() == section.toLowerCase()"
                  (click)="changeActiveType(section)">
            <span translate>{{ 'common.navServices.' + section.toLowerCase() }}</span>
          </button>
        }
      </div>
      <a [routerLink]="['/']" fragment="experience" class="nav-link active" (click)="toggleMenu()">
        <span translate>common.nav.experience</span>
      </a>
      <a [routerLink]="['/']" fragment="about" class="nav-link active" (click)="toggleMenu()">
        <span translate>common.nav.about</span>
      </a>
      <a [routerLink]="['/']" fragment="news" class="nav-link active" (click)="toggleMenu()">
        <span translate>common.nav.news</span>
      </a>
      <a [routerLink]="['/']" fragment="contact" class="nav-link active" (click)="toggleMenu()">
        <span translate>common.nav.contact</span>
      </a>
    </nav>
  </div>
</div>
